import type { IAttachment } from '@Models/attachment.model';
import { EAuctionStatus } from '@Enums/auctionStatus.model';
import { getCookie } from '@/services/helpers.js';

export const useHelpers: () => any = () => {
  const { $i18n } = useNuxtApp();

  const slugify: (text: string) => string = (text) => {
    if (!text) return '';

    const from = 'ąãàáäâęẽèéëêłśìíïîõòóöôùúüûñńçćżź·/_,:;';
    const to = 'aaaaaaeeeeeelsiiiiooooouuuunncczz------';
    for (let i = 0, l = from.length; i < l; i++) {
      text = text.toLowerCase();
      text = text.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    return text
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  };

  const downloadFile = (file: any, attachment: IAttachment): any => {
    const blob = getFile(file, attachment);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = attachment.name;
    return link;
  };

  const getFile = (file: any, attachment: any): any => {
    let blob = new Blob([file]);
    if (attachment.type === 'pdf') {
      blob = new Blob([file], {
        type: 'application/pdf'
      });
    }

    if (attachment.type === 'png') {
      blob = new Blob([file], {
        type: 'image/png'
      });
    }

    if (attachment.type === 'jpg') {
      blob = new Blob([file], {
        type: 'image/jpg'
      });
    }

    if (attachment.type === 'jpeg') {
      blob = new Blob([file], {
        type: 'image/jpeg'
      });
    }
    return blob;
  };

  const isAuctionEnded = (itemStatus: EAuctionStatus) => {
    return (
      itemStatus === EAuctionStatus.CLOSED ||
      itemStatus === EAuctionStatus.KNOCKED ||
      itemStatus === EAuctionStatus.NOT_EVENTUATED ||
      itemStatus === EAuctionStatus.REMIT_TO_COURT
    );
  };

  const isAuctionStartedOrEnded = (itemStatus: EAuctionStatus) => {
    return itemStatus === EAuctionStatus.IN_PROGRESS || isAuctionEnded(itemStatus);
  };

  const translateAuctionStatus = (itemStatus: EAuctionStatus) => {
    const status: { [key: string]: string } = {
      [EAuctionStatus.CREATED]: $i18n.t('item.status.planned'),
      [EAuctionStatus.USED]: $i18n.t('item.status.planned'),
      [EAuctionStatus.IN_PROGRESS]: $i18n.t('item.status.inProgress'),
      [EAuctionStatus.KNOCKED]: $i18n.t('item.status.finished'),
      [EAuctionStatus.CLOSED]: $i18n.t('item.status.finished'),
      [EAuctionStatus.CANCELLED]: $i18n.t('item.status.cancel'),
      [EAuctionStatus.NOT_EVENTUATED]: $i18n.t('item.status.notEventuated'),
      [EAuctionStatus.REMIT_TO_COURT]: $i18n.t('item.status.finished'),
      [EAuctionStatus.CLOSED_WITH_DOC]: $i18n.t('bailiffItemsList.auctionStatus.endedWithDocs'),
      [EAuctionStatus.CLOSED_NO_DOC]: $i18n.t('bailiffItemsList.auctionStatus.endedWithoutDocs')
    };
    return status[itemStatus] || $i18n.t('empty');
  };

  const setValueWithMoneyFormat = (amount: any, decimalCount = 2, decimal = ',', thousands = ' ') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      let i: any = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      const valueOverThousand = `${negativeSign}${
        j ? i.substr(0, j) + thousands : ''
      }${i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)}`;
      const valueBelowThousand = decimalCount
        ? `${
            decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          }`
        : '';
      return `${valueOverThousand}${valueBelowThousand}`;
    } catch (e) {
      console.warn(e);
    }
  };

  const setCurrency = (value: any) => {
    const newValue = setValueWithMoneyFormat(value);
    return `${newValue} PLN`;
  };

  const setCurrencyWithoutPLN = (value: any) => {
    const newValue = setValueWithMoneyFormat(value);
    return `${newValue}`;
  };

  const translateMainCategory = (data: string) => {
    if (data === 'REAL_ESTATE') {
      return getCookie('i18n_redirected') === 'pl' ? 'Nieruchomość' : 'Real estate';
    } else if (data === 'MOVABLE') {
      return getCookie('i18n_redirected') === 'pl' ? 'Ruchomość' : 'Movable';
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return {
    isAuctionStartedOrEnded,
    isAuctionEnded,
    slugify,
    downloadFile,
    translateAuctionStatus,
    setCurrency,
    setCurrencyWithoutPLN,
    translateMainCategory,
    scrollToTop
  };
};
